import React from 'react';
import styled from '@emotion/styled';
import { Blog } from '@content/types/blog';

import { ArticleSectionImageText } from '@components/article/ArticleSectionImageText';
import { ArticleSectionText } from '@components/article/ArticleSectionText';
import { ArticleSectionQuote } from '@components/article/ArticleSectionQuote';
import { ArticleSectionButtonText } from '@components/article/ArticleSectionButtonText';
import { ArticleSectionVideo } from '@components/article/ArticleSectionVideo';

const ArticleIntroduction = styled.p`
  max-width: 730px;
  margin: auto;
  line-height: 1.5;
`;

interface ArticleContentProps {
  article: Blog | null;
  langcode: string;
  urls: Array<string>;
}

export const ArticleContent = (props: ArticleContentProps) => {
  return (
    <div>
      <ArticleIntroduction>{props.article && props.article.introduction}</ArticleIntroduction>
      {props.article && props.article.sections && props.article.sections.length > 0
        ? props.article.sections.map((section, index) =>
            section.type === 'image-text' ? (
              <ArticleSectionImageText key={index} data={section} />
            ) : section.type === 'quote' ? (
              <ArticleSectionQuote key={index} data={section} />
            ) : section.type === 'button-text' ? (
              <ArticleSectionButtonText
                key={index}
                data={section}
                langcode={props.langcode}
                urls={props.urls}
              />
            ) : section.type === 'video' ? (
              <ArticleSectionVideo key={index} data={section} />
            ) : (
              <ArticleSectionText key={index} data={section} />
            )
          )
        : false}
    </div>
  );
};
